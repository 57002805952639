import "./Loading.scss"

const Loading = () => {
    return (
        <div className="loading">
            <span className="loading-back">
                <span>L</span>
                <span>o</span>
                <span>a</span>
                <span>d</span>
                <span>i</span>
                <span>n</span>
                <span>g</span>
            </span>
        </div>
    )
}

export default Loading
